.navbar{
    box-shadow: none;
}
.alert{
    position: fixed;
    transition: cubic-bezier(0.165, 0.84, 0.44, 1);
    top:30px;
    width:100%;
}
.navbar .navbar-translate {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    transform: translate3d(0px, 0, 0);
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

.h4class {
    font-size: 18px;
    line-height: 40px;
    color: #fff;
    margin-top: 20px;
    padding: 0 14px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAICAYAAAAm06XyAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA4BpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo3ZDlkMWM4MS05YmUzLTA2NDMtYTBiNi1lMGNjOTgxMjA5ODAiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RUJFRkFFNjk3NjE1MTFFRDgwOUNBQzc4MjExQTRDN0IiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RUJFRkFFNjg3NjE1MTFFRDgwOUNBQzc4MjExQTRDN0IiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoxZTFkZjBlOC04M2NhLTU4NGItOWY3Yi05M2RhMzI5NGQ3OTUiIHN0UmVmOmRvY3VtZW50SUQ9ImFkb2JlOmRvY2lkOnBob3Rvc2hvcDo0ODU3ODFjNS1hYjkxLTYzNGItOWRhMC1hZGZhM2RkZWRlMDYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4EFX3cAAAAhUlEQVR42mL4////GiBWBGIGEnEFCwMDw1MgvgvEMkD8jIE4sByIrWCmNAHxbyCWJcLG1UD8Goh5kAUb/0OAKh6N+4H4FRAzgfjokuVQA9SwaDwMxHeQxbCZXgQ1QBNJ7CQQX0VXi8t5hUD8AYgtgHgLEF/Epg5fwEQB8R8g3opLDUCAAQC/yVDrACry1gAAAABJRU5ErkJggg==) no-repeat right 14px center #bca267;
    cursor: pointer;
}
.pic{
    text-align: center;
}


.hpage1 .ftt li{
	border-bottom: 1px solid #3d3d3d;float: none;width: calc((100% - 60px)/2);margin-bottom: 24px;
}
.hpage1 .ftt li:nth-child(2n){
	float: none;
}
.vban {
    width: 590px;
    position: relative;
    margin-top: 52px;
    overflow: hidden;
    height: 335px;
}

@media only screen and (max-width:1240px) {
    .vban{
        width: 485px;height: 280px;
    }
    .vban .swiper-slide{
        height: 280px;
    }
}
@media only screen and (max-width:1040px) {
    .hpage1 .ft li {
        margin-bottom: 10px;
        width: auto;
        float: none;
    }
    .vban{
        width: auto;
    }
    .vban{
        margin-top: 20px;
    }
    .vban .spot{
        padding: 2px 0;
    } 
}


 