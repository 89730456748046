.wrap{
	width: 1200px;margin: 0 auto;
}
.hlogo{
	padding-top: 75px;width: 711px;margin: 0 auto;
}
.hlogo img{
	display: block;width: 100%;
}
.hpage1{
	padding: 20px 0 0;
}
.hpage1 h1{
	font-size: 48px;
	line-height: 1.6;
	color: #bca267;text-align: center;font-family: 'whz';
}
.hpage1 .bd{
	margin-top: 52px;height: 485px;
}
.hpage1 .bd .vide{
	float: left;width: 735px;height: 100%;
}
.hpage1 .bd .txt{
	float: right;width: calc(100% - 735px);background: #f9f9f9;padding-top: 40px;-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	box-sizing: border-box;height: 100%;text-align: center;
}
.hpage1 .bd .vide video{
	width: 100%;object-fit: cover;display: block;height: 100%;
}
.hpage1 .bd .txt h3{
	font-size: 24px;
	line-height: 1.6;
	color: #3d3d3d;margin-bottom: 6px;
}
.hpage1 .bd .txt h2 span{
	font-size: 30px;
	line-height: 1.6;
	color: #bca267;position: relative;display: inline-block;
}
.hpage1 .bd .txt h2 span:before,
.hpage1 .bd .txt h2 span::after{
	position: absolute;
	left: -45px;right: 0;
	top: 50%;z-index: 1;
	transform:translateY(-50%);
	-ms-transform:translateY(-50%);  /* IE 9 */
	-moz-transform:translateY(-50%);     /* Firefox */
	-webkit-transform:translateY(-50%); /* Safari 和 Chrome */
	-o-transform:translateY(-50%); width: 35px;height: 1px;margin-top: -1px;content: "";background: #bca267;
}
.hpage1 .bd .txt h2 span::after{
	left: auto;right: -45px;
}
.hpage1 .bd .txt p{
	font-size: 16px;
	line-height: 1.7;
	color: #3d3d3d;margin: 50px 0 74px;
}
.hpage1 .bd .txt h6{
	font-size: 14px;
	line-height: 1.6;
	color: #3d3d3d;
}
.hpage1 .bd .txt h6 span{
	width: 20px;height: 1px;background: #3d3d3d;margin: 0 15px;display: inline-block;vertical-align: middle;
}
.hpage1 .bd .txt .btn{
	width: 304px;line-height: 46px;background: #bca267;display: block;margin: 0 auto;
}
.hpage1 .bd .txt .btn span{
	font-size: 18px;
	line-height: 46px;
	color: #fff;padding-left: 34px;background: url(../images/qm2.png) no-repeat left center;display: inline-block;
}
.hpage1 .bd .txt .fm{
	border: 1px solid #3d3d3d;width: 300px;height: 30px;display: flex;justify-content: space-between;align-items: center;
	margin: 6px auto 50px;
}
.hpage1 .bd .txt .fm h4{
	font-size: 18px;
	line-height: 30px;
	color: #3d3d3d;width: 136px;
}
.hpage1 .bd .txt .fm h4.on{
	font-size: 18px;
	line-height: 30px;
	color: #ffffff;width: calc(100% - 136px);background: #3d3d3d;
}
.hpage1 .ft{
	margin-top: 30px;
}
.hpage1 .ft h4{
	font-size: 24px;
	line-height: 1.7;
	color: #3d3d3d;margin-bottom: 24px;
}
.hpage1 .ft li{
	border-bottom: 1px solid #3d3d3d;float: left;width: calc((100% - 60px)/2);margin-bottom: 24px;
}
.hpage1 .ft li:nth-child(2n){
	float: right;
}
.hpage1 .ft li a{
	display: flex;justify-content: space-between;align-items: center;padding: 0 8px;
}
.hpage1 .ft li h5{
	font-size: 18px;
	line-height: 38px;
	color: #3d3d3d;background: url(../images/qm3.png) no-repeat left center;padding-left: 30px;word-break:keep-all;
	white-space:nowrap;
	overflow:hidden;
	text-overflow:ellipsis;max-width: 80%;
}
.hpage1 .ft li h5 i{
	margin-right: 20px;
}

.hpage1 .ft li h6{
	font-size: 14px;
	line-height: 38px;
	color: #3d3d3d;
}
.hpage1 .ft li:hover h5{
	    background: url(../images/qm3-1.png) no-repeat left center;
}
.hpage1 .ft li:hover h5,
.hpage1 .ft li:hover h6{
	color: #bca267;
}
.hpage1 .ft li:hover{
	border-color: #bca267;
}
.tit{
	text-align: center;
}
.tit h2{
	font-size: 36px;
	line-height: 1.7;
	color: #3d3d3d;font-family: 'whz';
}
.tit h2 span{
	color: #bca267;
}
.tit p{
	font-size: 18px;
	line-height: 1.7;
	color: #9d9d9d;text-transform: uppercase;
}
.hpage2{
	padding: 92px 0 72px;
}
.hpage2 .del{
	font-size: 16px;
	line-height: 26px;text-align: center;
	color: #3d3d3d;margin: 36px 0 16px;
}

.hpage3 .bd{
	margin-top: 32px;
}
.hpage3 .bd .pic{
	float: left;width: 445px;
}
.hpage3 .bd .pic img{
	width: 100%;display: block;
}
.hpage3 .bd .txt{
	float: right;width: calc(100% - 530px);margin-top: 60px;padding-right: 84px;-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	box-sizing: border-box;
}
.hpage3 .bd .txt h3{
	font-size: 30px;
	line-height: 1.6;
	color: #3d3d3d;position: relative;
}
.hpage3 .bd .txt h3:before{
	content: "";position: absolute;
	left: -30px;
	top: -76px;z-index: 1;background: url(../images/qm5.png) no-repeat center/cover;width: 265px;height: 143px;
}
.hpage3 .bd .txt h6{
	font-size: 16px;
	line-height: 1.8;
	color: #bca267;font-weight: bold;margin: 30px 0;
}
.hpage3 .bd .txt p{
	font-size: 14px;
	line-height: 34px;
	color: #737373;
}
.hpage3 h4{
	font-size: 24px;
	line-height: 1.7;
	color: #3d3d3d;margin: 20px 0 15px;
}
.hpage3 .swipic{
	position: relative;overflow: hidden;
}
.hpage3 .swipic li{
	height: 245px;
}
.hpage3 .swipic li a{
	height: 100%;position: relative;display: block;overflow: hidden;
}
.hpage3 .swipic li img{
	height: 100%;object-fit: cover;width: 100%;transition:all 0.3s;
	-ms-transition:all 0.3s;  /* IE 9 */
	-moz-transition:all 0.3s;     /* Firefox */
	-webkit-transition:all 0.3s; /* Safari 和 Chrome */
	-o-transition:all 0.3s;
}
.hpage3 .swipic li:hover img{
	transform:scale(1.1);
	-ms-transform:scale(1.1);  /* IE 9 */
	-moz-transform:scale(1.1);     /* Firefox */
	-webkit-transform:scale(1.1); /* Safari 和 Chrome */
	-o-transform:scale(1.1); 
}
.hpage3 .swipic li h6{
	position: absolute;
	right: 6px;
	bottom: 6px;z-index: 1;font-size: 14px;
	line-height: 1.7;
	color: #fff;
}
.hpage3 .ovs{
	position: relative;
}
.hpage3 .ovs .prev,
.hpage3 .ovs .next{
	position: absolute;
	left: -37px;
	top: 50%;z-index: 1; background: url(../images/prev.png) no-repeat center;width: 30px;height: 30px;margin-top: -10px;cursor: pointer;
}
.hpage3 .ovs .prev:hover,
.hpage3 .ovs .next:hover{
	background: url(../images/prev-1.png) no-repeat center;
}
.hpage3 .ovs .next{
	left: auto;right: -37px;transform:rotate(180deg);
	-ms-transform:rotate(180deg);  /* IE 9 */
	-moz-transform:rotate(180deg);     /* Firefox */
	-webkit-transform:rotate(180deg); /* Safari 和 Chrome */
	-o-transform:rotate(180deg);
}
.hpage4{
	padding: 88px 0 52px;
}
.hpage4 .hd{
	margin-top: 50px;display: flex;justify-content: space-between;
}
.hpage4 .hd .pic{
	width: 600px;
}
.hpage4 .hd .pic img{
	width: 100%;height: 100%;display: block;object-fit: cover;
}
.hpage4 .hd .txt{
	width: calc(100% - 600px);background: #f9f9f9;
	padding: 0 70px;-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	box-sizing: border-box;padding-top: 60px;
}
.hpage4 .hd .txt h3{
	font-size:30px ;
	line-height: 1.6;
	color: #3d3d3d;position: relative;text-align: center;
}
.hpage4 .hd .txt h3::before{
	content: "";position: absolute;
	left: 0;right: 0;
	top: 50%;z-index: 1;background: url(../images/qm14.png) no-repeat center/cover;width: 243px;height: 50px;margin: -20px auto 0;
}
.hpage4 .hd .txt .del{
	font-size: 16px;
	line-height: 36px;
	color: #878787;margin-top: 50px;border-bottom: 1px solid #e4dbc6;padding-bottom: 60px;
}
.hpage4 .bd li{
	padding: 25px 0;border-bottom: 1px solid #ebebeb;display: flex;justify-content: space-between;
}
.hpage4 .bd li .txt{
	width: calc(100% - 665px);
}
.hpage4 .bd li .swipban{
	width: 590px;position: relative;margin-top: 52px;overflow: hidden;height: 335px;
}
.hpage4 .bd li .swipban .swiper-slide{
	height: 335px;width: 100%;
}
.hpage4 .bd li .swipban .swiper-slide img{
	width: 100%;height: 100%;display: block;object-fit: cover;
}
.hpage4 .bd li .swipban .spot{
	position: absolute;
	left: 0;right: 0;
	bottom: 0;z-index: 1;background: rgba(0,0,0,0.5);text-align: center;padding: 10px 0;
}
.hpage4 .bd li .swipban .spot span{
	border: 1px solid #fcfcfc;width: 7px;height: 7px;border-radius: 50%;opacity: 1;background: none;
}
.hpage4 .bd li .swipban .spot  .swiper-pagination-bullet-active{
	background: #fff;
}
.hpage4 .bd li .txt h2{
	font-size:24px ;
	line-height: 1.6;
	color: #bca267;margin-bottom: 10px;
}
.hpage4 .bd li .txt p{
	font-size:14px ;
	line-height: 34px;
	color: rgba(61,61,61,0.8);
}
.hpage4 .bd li .ovs{
	margin-top: 20px;
}
.hpage4 .bd li h4{
	font-size:18px ;
	line-height: 40px;
	color: #fff;padding: 0 14px;background: url(../images/qm38.png) no-repeat right 14px center #bca267;cursor: pointer;
}
.hpage4 .bd li table{
	width: 100%;display: block;border: 1px solid #bca267;border-collapse: collapse;position: relative;overflow: hidden;
}
.hpage4 .bd li table td{
	border:unset !important;
}
.hpage4 .bd li table td{
	padding: 8px 0;font-size:12px ;
	line-height: 1.7;
	color: #3d3d3d;border-bottom: 1px solid #bca267 !important;
}
.hpage4 .bd li table tr:last-child td{
	border-bottom: none;
}
.hpage4 .bd li table td:nth-child(1){
	color: #bca267;padding-left: 10px;
}
.hpage4 .bd li table td:nth-child(4){
	padding-right: 10px;
}
.hpage4 .bd li table::before{
	content: "";position: absolute;
	right: -30px;
	bottom: 0;z-index: 1;background: url(../images/qm6.png) no-repeat center/cover;width: 156px;height: 160px;
}
.hpage4 .bd li:last-child{
	border-bottom: none;
}

.hpage5 ul{
	background: #f9f9f9;font-size: 0;text-align: center;
}
.hpage5 li{
	padding: 36px 0 24px;display: inline-block;vertical-align: middle;width: 33%;-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	box-sizing: border-box;transition:all 0.3s;
	-ms-transition:all 0.3s;  /* IE 9 */
	-moz-transition:all 0.3s;     /* Firefox */
	-webkit-transition:all 0.3s; /* Safari 和 Chrome */
	-o-transition:all 0.3s;position: relative;top: 0;
}
.hpage5 li .ico{
	height: 71px;
}
.hpage5 li h3{
	font-size:18px ;
	line-height: 1.7;
	color: #3d3d3d;margin: 8px 0 4px;
}
.hpage5 li p{
	font-size:16px ;
	line-height: 1.7;
	color: rgba(61,61,61,0.8);
}
.hpage5 li:nth-child(2){
	width: 34%;
}
.hpage6{
	padding: 70px 0;
}
.hpage6 ul{
	margin-top: 50px;
}
.hpage6 li{
	float: left;width: calc((100% - 110px)/4);margin-right: 36px;
	border: 1px solid #bca267;-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	box-sizing: border-box;text-align: center;height: 260px;position: relative;padding-top: 45px;transition:all 0.3s;
	-ms-transition:all 0.3s;  /* IE 9 */
	-moz-transition:all 0.3s;     /* Firefox */
	-webkit-transition:all 0.3s; /* Safari 和 Chrome */
	-o-transition:all 0.3s;top: 0;position: relative;
}
.hpage6 li:nth-child(4n){
	margin-right: 0;
}
.hpage6 li .ico{
	height: 64px;margin: 0 auto;
}
.hpage6 li h3{
	font-size:18px ;
	line-height: 1.2;
	color: #3d3d3d;margin-top: 20px;
}
.hpage6 li h6{
	font-size:14px ;
	line-height: 44px;
	color: #fff;background: #bca267;position: absolute;
	left: 0;right: 0;
	bottom: 0;z-index: 1;
}

.hpage7{
	padding: 70px 0 60px;
}
.hpage7 ul{
	margin-top: 50px;display: flex;justify-content: space-between;align-items: center;
	padding-bottom: 30px;position: relative;
}
.hpage7 ul::before{
	content: "";position: absolute;
	left: 0;right: 0;
	bottom: 0;z-index: 1;background: url(../images/qm39.png) no-repeat center/cover;height: 13px;
}
.hpage7 li{
	width: 33%;
}
.hpage7 li:nth-child(3){
	width: 34%;
}
.hpage7 li:nth-child(3) .ts{
	justify-content: flex-end;
}
.hpage7 li .ts{
	display: flex;justify-content: flex-start;align-items: center;
}
.hpage7 li h3{
	font-size:24px ;
	line-height: 1.6;
	color: #3d3d3d;margin-right: 18px;
}
.hpage7 li h6::before{
	content: "";position: absolute;
	left: 0;
	top: 50%;z-index: 1;width: 1px;height: 40px;background: #c4ad79;margin-top: -20px;
}
.hpage7 li h6{
	font-size:18px ;
	line-height: 28px;
	color: #3d3d3d;position: relative;padding-left: 20px;
}
.hpage7 li h3 i{
	font-size: 48px;color: #bca267;font-style: italic;margin-right: 6px;
}

.hpage8 ul{
	margin-top: 40px;
}
.hpage8 li{
	float: left;margin-bottom: 48px;width: calc((100% - 56px)/2);border: 1px solid #bca267;-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	box-sizing: border-box;box-shadow: 0 0 10px 0 rgba(80,80,80,0.2);position: relative;
	padding: 32px 32px 40px;transition:all 0.3s;
	-ms-transition:all 0.3s;  /* IE 9 */
	-moz-transition:all 0.3s;     /* Firefox */
	-webkit-transition:all 0.3s; /* Safari 和 Chrome */
	-o-transition:all 0.3s;position: relative;top: 0;
}
.hpage8 li:nth-child(2n){
	float: right;
}
.hpage8 li::before{
	content: "";position: absolute;
	right: 0;
	top: 0;z-index: 1;background: url(../images/qm37.png) no-repeat center/cover;width: 59px;height: 65px;
}
.hpage8 li .ts{
	display: flex;justify-content: flex-start;align-items: center;
}
.hpage8 li .ico{
	width: 66px;height: 66px;border-radius: 50%;border: 3px solid #e4dac2;overflow: hidden;
}
.hpage8 li .ico img{
	width: 100%;height: 100%;display: block;object-fit: cover;
}
.hpage8 li .txt{
	margin-left: 24px;
}
.hpage8 li h3{
	font-size:18px ;
	line-height: 1.6;
	color: #3d3d3d;margin-bottom: 4px;
}
.hpage8 li h6{
	font-size:16px ;
	line-height: 1.7;
	color: #bca267;
}
.hpage8 li .del{
	margin-top: 15px;font-size:15px ;
	line-height: 26px;
	color: #3d3d3d;height: 78px;overflow: hidden;
}
.hpage11{
	padding: 22px 0 86px;
}
.hpage11 .wrap{
	display: flex;justify-content: space-between;align-items: center;
	border: 1px solid #bca267;
}
.hpage11 p{
	font-size:20px ;
	line-height: 40px;
	color: #3d3d3d;text-align: center;width: 50%;padding: 50px 0;
}
.hpage11 h6{
	background: #bca267;
	font-size:24px ;
	line-height:44px;
	color: #ffffff;width: 50%;padding: 50px 0;text-align: center;
}
.hpage11 h6 span{
	font-weight: bold;color: #ffffff;
}
.hpage10 h4{
	font-size:24px ;
	line-height: 1.7;
	color: #3d3d3d;margin-bottom: 12px;
}
.hpage10 ul{
	display: flex;justify-content: space-between;flex-wrap: wrap;
}
.hpage10 li{
	margin-bottom: 42px;width: calc((100% - 30px)/2);
}
.hpage10 li h3{
	font-size:18px ;
	line-height: 1.7;
	color: #bca267;padding-left: 30px;background: url(../images/qm31.png) no-repeat left top 3px;margin-bottom: 10px;
}
.hpage10 li .del{
	font-size:13px ;
	line-height: 30px;
	color: #3d3d3d;padding-left: 30px;background: url(../images/qm32.png) no-repeat left top 5px;
}
.hpage9{
	padding-bottom: 32px;
}
.hpage9 .bd{
	margin-top: 40px;
}
.hpage9 .bd .pic{
	float: right;width: 50%;height: 396px;padding-top: 70px;-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	box-sizing: border-box;text-align: center;
}
.hpage9 .bd .txt{
	float: left;width: 50%;margin-top: 77px;
}
.hpage9 .bd .pic h6{
	font-size:22px ;
	line-height: 1.7;
	color: #c00000;margin-bottom: 10px;
}
.hpage9 .bd .pic{
	font-size:22px ;
	line-height: 1.7;
	color: #c00000;margin-bottom: 10px;
}
.hpage9 .bd .pic h6 i{
	padding: 0 10px;line-height: 50px;background: #c00000;border-radius: 8px;font-size:20px ;color: #fff;
	display: inline-block;vertical-align: middle;margin-right: 16px;
}
.hpage9 .bd .pic h6 span{
	font-size: 60px;font-weight: bold;color: #c00000;line-height: 1.7;display: inline-block;vertical-align: middle;
}
.hpage9 .bd .pic{
	background: url(../images/qm43.png) no-repeat center/100% 100%;
}
.hpage9 .bd .pic .btn{
	margin: 0 auto;
	background-color: unset;
	box-shadow: none;
	display: block;
}
.hpage9 .bd .pic .contain{
	width: 100%;
	background-color: unset;
	box-shadow: none;
	display: block;border-top: 1px dashed #c4ad79;margin: 30px auto auto;padding-top: 40px;
}
.hpage9 .bd .pic .btn span{
	background-color: unset;
	box-shadow: none;
	width: 255px;line-height: 40px;text-align: center;
	font-size: 24px;font-weight: bold;color: #fff;margin: 0 auto;background: #c00000;display: inline-block;border-radius: 22px;
}
.hpage9 .bd .txt h3{
	font-size:30px ;
	line-height: 1.6;
	color: #3d3d3d;position: relative;text-align: center;
}
.hpage9 .bd .txt h3::before{
	content: "";position: absolute;
	left: 0;right: 0;
	top: 50%;z-index: 1;background: url(../images/qm29.png) no-repeat center/cover;width: 288px;height: 50px;margin: -20px auto 0;
}
.hpage9 .bd .txt .del{
	font-size: 16px;
	line-height: 42px;
	color: #3d3d3d;margin-top: 50px;position: relative;padding-bottom: 64px;text-align: center;
}
.hpage9 .bd .txt .del::before{
	content: "";position: absolute;
	left: 0;right: 0;
	bottom: 0;z-index: 1; width: 364px;height: 1px;background: #e4dbc6;margin: 0 auto;
}

.fokx{
	display: none;position: fixed;
	right: 0;
	top: 40%;z-index: 222;
}
.hpage1 h1.sj{
	display: none;
}
.hlogo .sj{
	display: none;
}

.hpage4 .bd li h4.on{
	background: url(../images/qm42.png) no-repeat right 14px center #bca267;
}
.hpage4 .bd li .ds{
	display: none;
}

.hpage1 .bd .txt .btn:hover{
	background: #ac945e;
}
.hpage8 li:hover,
.hpage6 li:hover,
.hpage5 li:hover{
	top: -10px;
}
.hpage9 .bd .pic .btn span:hover{
	background: #a40505;
}

.hpage9 .fts{
	margin-top: 40px;
}
.hpage9 .fts li{
	float: left;width: calc((100% - 84px)/2);background: url(../images/qm44.png) no-repeat center/100% 100%;height: 358px;padding-top: 40px;-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	box-sizing: border-box;
}
.hpage9 .fts li:nth-child(2){
	float: right;
}
.hpage9 .fts li h6{
	font-size: 18px;
	line-height: 1.7;
	color: #3e3e3e;text-align: center;
}
.hpage9 .fts li h5{
	margin: 20px auto;width: 370px;
}
.hpage9 .fts li h5 i{
	font-size:18px ;
	line-height: 34px;
	color: #3e3e3e;padding: 0 36px;display: inline-block;vertical-align: middle;border: 1px solid #c4ad79;
}
.hpage9 .fts li h5 span{
	background: #bca267;padding: 0 50px;line-height: 36px;display: inline-block;vertical-align: middle;
	font-size: 24px;color: #fff;font-weight: bold;
}
.hpage9 .fts li h4{
	text-align: center; 
}
.hpage9 .fts li h4 i{
	    padding: 0 8px;
	    line-height: 46px;
	    background: #bca267;
	    border-radius: 8px;
	    font-size: 18px;
	    color: #fff;
	    display: inline-block;
	    vertical-align: middle;
	    margin-right: 10px;display: inline-block;vertical-align: middle;
}
.hpage9 .fts li h4 span{
	display: inline-block;vertical-align: middle;font-size:40px ;
	line-height: 1.6;
	color: #bca267;font-weight: bold;
}
.hpage9 .fts li h4 span font{
	  font-size: 18px;font-weight: initial;
}
.hpage9 .fts li .btn{
	background-color: #fff;
	box-shadow:none;
	margin: 40px 26px 0;padding-top: 30px;    border-top: 1px dashed #c4ad79;display: block;text-align: center;
} 
.hpage9 .fts li .btn span{
		background-color: #fff;
	    width: 355px;
	    line-height: 46px;
	    text-align: center;
	    font-size: 24px;
	    font-weight: bold;
	    color: #fff;
	    margin: 0 auto;
	    background: #bca267;
	    display: inline-block;
	    border-radius: 24px;
		box-shadow:none;
}
.hpage9 .fts li .lsele{
	width: 360px;height: 46px;line-height: 1.7;background: url(../images/qm45.png) no-repeat right center;
	font-size: 17px;color: #3e3e3e;border: none;border-bottom: 1px solid #3e3e3e;margin: 20px auto 0;display: block;border-radius: initial;
}
.hpage9 .fts li:nth-child(2) h4{
	margin-top: 10px;
}
