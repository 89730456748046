/*pad*/

img {
    max-width: 100%;
}

.gh {
    height: 20px;
    width: 20px;
    position: absolute;
    transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1) 0s;
    -webkit-transition: all 0.5s cubic-bmezier(0.7, 0, 0.3, 1) 0s;
    -ms-transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1) 0s;
    cursor: pointer;
    z-index: 9999;
    display: none;
}

.selected .gh {
    transform: rotate(90deg);
}

.gh a {
    background-color: #fff;
    display: block;
    margin: 0 auto;
    height: 2px;
    margin-top: -1px;
    position: relative;
    top: 50%;
    transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1) 0s;
    -webkit-transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1) 0s;
    -ms-transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1) 0s;
    width: 100%;
    border-radius: 2px;
}

.gh a:after {
    width: 100%;
}

.gh a:before {
    width: 100%;
}

.gh a:after,
.gh a:before {
    background-color: #fff;
    content: "";
    display: block;
    height: 2px;
    left: 0;
    position: absolute;
    transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1) 0s;
    -webkit-transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1) 0s;
    -ms-transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1) 0s;
}

.gh a:after {
    top: 7px;
}

.gh a:before {
    top: -7px;
}

.selected .gh a:after,
.selected .gh a:before {
    top: 0;
}

.selected .gh a:before {
    transform: translateY(0px) rotate(-45deg);
    -webkit-transform: translateY(0px) rotate(-45deg);
    -ms-transform: translateY(0px) rotate(-45deg);
    background: #fff;
    width: 100%;
}

.selected .gh a:after {
    transform: translateY(0px) rotate(45deg);
    -webkit-transform: translateY(0px) rotate(45deg);
    -ms-transform: translateY(0px) rotate(45deg);
    background: #fff;
    width: 100%;
}

.selected .gh a {
    background-color: transparent !important;
}



@media only screen and (max-width:1240px) {
	.wrap{
		width: 1000px;
	}
 .hpage1 .bd{
	 height: 395px;
 }
	.hpage1 .bd .vide{
		width: 570px;
	}
	.hpage1 .bd .txt{
		width: calc(100% - 570px);padding-top: 26px;
	}
	.hpage1 .bd .txt p{
		margin: 30px auto 40px;
	}
	.hpage1 .ft li h5{
		font-size: 16px;
	}
	.hpage3 .swipic li{
		height: 196px;
	}
	.hpage4 .hd .txt{
		width: calc(100% - 520px);padding: 30px 44px 0;
	}
	.hpage4 .hd .pic{
		width: 520px;
	}
	.hpage4 .hd .txt .del{
		font-size: 14px;padding-bottom: 30px;
	}
	.hpage4 .bd li .swipban .swiper-slide{
		height: 280px;
	}
	.hpage4 .bd li .swipban{
		width: 485px;height: 280px;
	}
	.hpage4 .bd li .txt{
		width: calc(100% - 510px);
	}
	.hpage4 .bd li .txt p{
		line-height: 30px;
	}
	.hpage6 li h3{
		font-size: 16px;
	}
	.hpage6 li h6{
		font-size: 12px;
	}
	.hpage6 li{
		height: 230px;padding-top: 30px;
	}
	.hpage7 li h3 i{
		font-size: 38px;
	}
	.hpage7 li h3{
		font-size: 20px;
	}
	.hpage7 li h6{
		font-size: 14px;line-height: 22px;
	}
	.tit h2{
		font-size: 30px;
	}
	.tit p{
		font-size: 16px;
	}
	.hpage8 li{
		padding: 20px 20px 30px;
	}
	.hpage8 li .del{
		font-size: 14px;
	}
	.hpage11 p{
		font-size: 16px;
	}
	.hpage11 h6{
		font-size: 20px;
	}
	.hpage9 .bd .pic{
		height: 330px;padding-top: 36px;
	}
	.hpage9 .bd .pic h6 span{
		font-size: 50px;
	}
	.hpage9 .bd .txt .del{
		padding-bottom: 26px;
	}
	.hpage9 .fts li{
		height: 294px;padding-top: 20px;
	}
	.hpage9 .fts li h5{
		margin: 20px auto;
	}
	.hpage9 .fts li h4 span{
		font-size: 30px;
	}
	.hpage9 .fts li h4 i{
		font-size: 16px;
	}
	.hpage9 .fts li .btn{
		margin: 26px 26px 0;padding-top: 20px;
	}
	.hpage9 .fts li .btn span{
		width: 310px;font-size: 20px;
	}
	.hpage9 .fts li .lsele{
		font-size: 15px;width: 320px
	}
	
}


@media only screen and (max-width:1040px) {
   .wrap{
	   width: auto;padding: 0 10px;
   }
   
   .hpage11{
	   padding: 2rem 0;
   }
   .hpage11 .wrap{
	   display: block;padding: 0;margin: 0 10px;
   }
   .hpage11 p{
	   width: auto;padding: 17px 12px;font-size: 15px;line-height: 30px;
   }
   .hpage11 h6{
	   font-size: 16px;line-height: 28px;padding: 20px 0;width: auto;
   }
   .hpage10 h4{
	   font-size: 20px;
   }
   .hpage10 ul{
	   display: block;
   }
   .hpage10 li{
	   width: auto;margin-bottom: 26px;
   }
   .hpage10 li h3{
	   font-size: 15px;background-size: 18px auto !important;padding-left: 22px;
   }
   .hpage10 li .del{
	   background-size: 18px auto !important;padding-left: 22px;line-height: 1.9;background: url(../images/qm32.png) no-repeat left top 2px;
   }
   .tit h2{
	   font-size: 22px;
   }
   .tit p{
	   font-size: 12px;
   }
   .hpage9 .tit h2 span{
	   display: none;
   }
   .hpage9 .bd{
	   margin-top: 20px;
   }
   .hpage9 .bd .txt,
   .hpage9 .bd .pic{
	   float: none;width: auto;
   }
   .hpage9 .bd .pic{
	   height: auto;padding: 25px 0 90px;
   }
   .hpage9 .bd .pic h6{
	   font-size: 16px;margin-bottom: 26px;
   }
   .hpage9 .bd .pic h6 span{
	   font-size: 22px;
   }
   .hpage9 .bd .pic .btn{
	   width: 166px;line-height: 30px;font-size: 16px;
   }
   .hpage9 .bd .txt{
	   margin-top: 30px;
   }
   .hpage9 .bd .txt h3{
	   font-size: 20px;
   }
   .hpage9 .bd .txt .del{
	   font-size: 14px;line-height: 2;padding-bottom: 30px;margin-top: 20px;
   }
   .hpage9 .bd .txt h3::before{
	   width: 165px;height: 26px;margin: -12px auto 0;
   }
   .hpage9 .bd .txt .del::before{
	   width: 300px;
   }
   .hpage8 ul{
	   margin-top: 20px;
   }
   .hpage8 li:nth-child(2n){
	   float: none;
   }
   .hpage8 li{
	   float: none;width: auto;margin-bottom: 30px;padding: 12px 16px;
   }
   .hpage8 li .ico{
	   width: 52px;height: 52px;border: 2px solid #e4dac2;
   }
   .hpage8 li h3{
	   font-size: 16px;margin-bottom: 0;
   }
   .hpage8 li h6{
	   font-size: 14px;
   }
   .hpage8 li .txt{
	   margin-left: 10px;
   }
   .hpage8 li .del{
	   height: auto;
   }
   .hpage8 li::before{
	   width: 42px;height: 48px;
   }
   .hpage7 .tit p{
	   padding: 0 58px;
   }
   .hpage7 ul{
	   margin-top: 20px;display: block;padding-bottom: 0;
   }
   .hpage7 ul::before{
	   display: none;
   }
   .hpage7 li:nth-child(3),
   .hpage7 li{
	   width: auto;padding: 20px 0; border-bottom: 1px solid #f7f7f7;
   }
   .hpage7 li:nth-child(3) .ts{
	       justify-content: flex-start;
   }
   .hpage7 li h3 i{
	   font-size: 48px;
   }
   .hpage7 li h6,
   .hpage7 li h3{
	   font-size: 17px;
   }
   .hpage7 li h6{
	   line-height: 26px;
   }
   .hpage7 .wrap{
	   padding: 0 20px;
   }
   .hpage7 li h3{
	   width: 126px;margin-right: 0;
   }
   .hpage6 ul{
	   margin-top: 30px;
   }
   .hpage6 li{
	   width: calc((100% - 12px)/2);margin-bottom: 10px;margin-right: 0;height: 180px;padding-top: 20px;
   }
   .hpage6 li:nth-child(2n){
	   float: right;
   }
   .hpage6 li .ico{
	   height: 40px;
   }
   .hpage6 li .ico img{
	   height: 100%;object-fit: contain;
   }
   .hpage6 li h3{
	   font-size: 14px;margin-top: 14px;
   }
   .hpage6 li h6{
	   font-size: 13px;line-height: 1.4;padding: 5px 12px;
   }
   .hpage4,
   .hpage6,
   .hpage7{
	   padding: 2rem 0;
   }
   .hpage5 li{
	   padding: 16px 0 12px;
   }
   .hpage5 li .ico{
	   height: 50px;overflow: hidden;
   }
   .hpage5 li .ico img{
	   height: 100%;object-fit: contain;display: block;margin: 0 auto;
   }
   .hpage5 li h3{
	   font-size: 14px;
   }
   .hpage5 li p{
	   font-size: 13px;line-height: 1.4;height: 2.8em;
   }
   .hpage4 .hd{
	   margin-top: 30px;display: block;
   }
   .hpage4 .hd .txt,
   .hpage4 .hd .pic{
	   width: auto;
   }
   .hpage4 .hd .txt h3{
	   font-size: 20px;
   }
   
   .hpage4 .hd .txt .del{
	   line-height: 28px;margin-top: 16px;
   }
   .hpage4 .hd .txt h3::before{
	   width: 150px;height: 30px;margin: -14px auto 0;
   }
   .hpage4 .hd .txt{
	   padding: 26px 10px;
   }
   .hpage4 .bd li{
	   display: block;
   }
   .hpage4 .bd li .swipban,
   .hpage4 .bd li .txt{
	   width: auto;
   }
   .hpage4 .bd li .txt h2{
	   font-size: 20px;
   }
   .hpage4 .bd li .txt p{
	   line-height: 26px;
   }
   
   .hpage4 .bd li h4{
	   font-size: 16px;
   }
   .hpage4 .bd li table td:nth-child(1){
	   padding-left: 5px;
   }
   .hpage4 .bd li table td:nth-child(4){
	   padding-right: 5px;
   }
   .hpage4 .bd li .swipban{
	   margin-top: 20px;
   }
   .hpage3 h4{
	   font-size: 20px;margin: 10px 0;
   }
   .hpage3 .swipic{
	   margin: 0 35px;
   }
   .hpage3 .swipic li h6{
	   font-size: 12px;word-break:keep-all;
	   white-space:nowrap;
	   overflow:hidden;
	   text-overflow:ellipsis;
   }
   .hpage3 .ovs .prev, .hpage3 .ovs .next{
	   background-size: 12px auto !important;left: 0;
   }
   .hpage3 .ovs .next{
	   right: 0;left: auto;
   }
   .hpage3 .bd .txt,
   .hpage3 .bd .pic{
	   float: none;width: auto;padding-right: 0;
   }
   .hpage3 .bd .txt{
	   margin-top: 30px;
   }
   .hpage3 .bd .txt h3{
	   font-size: 20px;
   }
   .hpage3 .bd .txt h6 br{
	   display: none;
   }
   .hpage3 .bd .txt h6{
	   font-size: 15px;margin: 15px 0;
   }
   .hpage3 .bd .txt p{
	   line-height: 28px;
   }
   .hpage3 .bd .txt h3:before{
	     left: 0;
	     top: -29px;
	     width: 59px;
	     height: 80px;
   }
   .hpage3 .bd .txt{
	   padding-bottom: 30px;
   }
   .hpage2 .del{
	   font-size: 14px;margin: 20px 0 10px;
   }
   .hpage2 .del br{
	   display: none;
   }
   .hpage2{
	   padding: 2rem 0;
   }
   .hpage1 .ft h4{
	   font-size: 20px;margin-bottom: 10px;
   }
   .hpage1 .ft li{
	   margin-bottom: 10px;width: auto;float: none;
   }
   .hpage1 .ft li:nth-child(2n){
	   float: none;
   }
   .hpage1 .ft li h5{
	   font-size: 13px;line-height: 28px;background-size: 10px auto !important;    padding-left: 15px;
   }
   .hpage1 .ft li h6{
	   font-size: 13px;line-height: 28px;
   }
   .hlogo{
	   padding-top: 25px;width: 220px;
   }
   .hpage1 h1{
	   font-size: 26px;
   }
   .hpage1 .bd{
	   margin-top: 20px;height: auto;
   }
   .hpage1 .bd .txt,
   .hpage1 .bd .vide{
	   width: auto;float: none;
   }
   .hpage1 .bd .txt{
	   padding: 15px 0 30px;
   }
   .hpage1 .bd .txt h3{
	   font-size: 19px;
   }
   .hpage1 .bd .txt h2 span{
	   font-size: 20px;
   }
   .hpage1 .bd .txt p{
	   font-size: 14px;margin: 24px 0 34px;
   }
   .hpage1 .bd .txt h6{
	   font-size: 12px;
   }
   .hpage1 .bd .txt .fm h4,
   .hpage1 .bd .txt .fm h4.on{
	   font-size: 14px;
   }
   .hpage1 .bd .txt .fm{
	   width: 220px;margin: 6px auto 30px;
   }
   .hpage1 .bd .txt .fm h4{
	   width: 84px;
   }
   .hpage1 .bd .txt .fm h4.on{
	   width: calc(100% - 84px);
   }
   .hpage1 .bd .txt .btn{
	   width: 220px;line-height: 30px;
   }
   .hpage1 .bd .txt .btn span{
	   font-size: 16px;line-height: 30px;background-size: 16px auto !important;padding-left: 23px;
   }
   
   .fokx{
	   display: block;
   }
   .fokx a{
	   display: block;width: 52px;
   }
   .fokx img{
	   width: 100%;display: block;
   }
   .hpage1 h1{
	   display: none;
   }
   .hpage1 h1.sj{
   	display: block;color: #3d3d3d;
   }
   .hpage1 h1.sj span{
	   color: #bca267;
   }
   .hlogo .pc{
   	display: none;
   }
   .hlogo .sj{
   	display: block;
   }
   .hpage1{
	   padding: 10px 0 0;
   }
  .hpage4 .bd li .swipban .spot{
	  padding: 2px 0;
  } 
  .hpage9 .bd .pic{
	  padding: 50px 0;
  }
  .hpage9 .bd .pic h6{
	  margin-bottom: 14px;
  }
  .hpage9 .bd .pic h6 i{
	  padding: 0 5px;font-size: 12px;margin-right: 8px;line-height: 34px;border-radius: 4px;
  }
  .hpage9 .fts li .btn,
  .hpage9 .bd .pic .btn{
	  width: auto;
      /* margin: 20px 20px 0; */
      padding-top: 20px;
  }
  .hpage9 .fts li .btn span,
 .hpage9 .bd .pic .btn span{
     width: 166px;
     line-height: 30px;
     font-size: 16px;
 }

 .hpage9 .fts{
	 margin-top: 20px;
 }
   .hpage9 .fts li{
	   float: none;margin: 0 10px 20px;width: auto;height: auto;padding: 30px 0;
   }
   .hpage9 .fts li h6{
	   font-size: 14px;
   }
   .hpage9 .fts li h5{
	   width: auto;margin: 10px auto;text-align: center;
   }
   .hpage9 .fts li h5 i{
	   font-size: 12px;line-height: 30px;padding: 0 10px;
   }
   .hpage9 .fts li h5 span{
	   padding: 0 20px;font-size: 16px;line-height: 32px;
   }
   .hpage9 .fts li h4 i{
	   font-size: 12px;padding: 0 4px;line-height: 28px;border-radius: 4px;
   }
   .hpage9 .fts li h4 span{
	   font-size: 20px;
   }
   .hpage9 .fts li h4 span font{
	   font-size: 12px;
   }
   .hpage9 .fts li .lsele{
	   width: 100%;margin: 10px auto;font-size: 12px;background-size: 8px auto;height: 32px;
   }
   .hpage9 .fts li:nth-child(2){
   	float: none;padding: 30px;-webkit-box-sizing: border-box;
   	-moz-box-sizing: border-box;
   	-o-box-sizing: border-box;
   	box-sizing: border-box;
   }
   
   
   
   
   
   
}

