/*pad*/

img {
    max-width: 100%;
}

.gh {
    height: 20px;
    width: 20px;
    position: absolute;
    transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1) 0s;
    -webkit-transition: all 0.5s cubic-bmezier(0.7, 0, 0.3, 1) 0s;
    -ms-transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1) 0s;
    cursor: pointer;
    z-index: 9999;
    display: none;
}

.selected .gh {
    transform: rotate(90deg);
}

.gh a {
    background-color: #fc4513;
    display: block;
    margin: 0 auto;
    height: 2px;
    margin-top: -1px;
    position: relative;
    top: 50%;
    transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1) 0s;
    -webkit-transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1) 0s;
    -ms-transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1) 0s;
    width: 100%;
    border-radius: 2px;
}

.gh a:after {
    width: 100%;
}

.gh a:before {
    width: 100%;
}

.gh a:after,
.gh a:before {
    background-color: #fc4513;
    content: "";
    display: block;
    height: 2px;
    left: 0;
    position: absolute;
    transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1) 0s;
    -webkit-transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1) 0s;
    -ms-transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1) 0s;
}

.gh a:after {
    top: 7px;
}

.gh a:before {
    top: -7px;
}

.selected .gh a:after,
.selected .gh a:before {
    top: 0;
}

.selected .gh a:before {
    transform: translateY(0px) rotate(-45deg);
    -webkit-transform: translateY(0px) rotate(-45deg);
    -ms-transform: translateY(0px) rotate(-45deg);
    background: #fc4513;
    width: 100%;
}

.selected .gh a:after {
    transform: translateY(0px) rotate(45deg);
    -webkit-transform: translateY(0px) rotate(45deg);
    -ms-transform: translateY(0px) rotate(45deg);
    background: #fc4513;
    width: 100%;
}

.selected .gh a {
    background-color: transparent !important;
}
@media only screen and (max-width:1440px) {
	.wrap {
		width: 1200px;
	}
	.header {
	    padding: 40px 0 10px;
	}
	.header .logo {
		height: 85px;
	}
	.fbanner {
	    padding: 30px 0 110px;
	}
	.fbanner .text h2 {
		font-size: 40px;line-height: 1.5;
	}
	.fbanner .text p {
		font-size: 20px;
	}
	.fbanner .text {
		margin-top: 70px;
	}
	.fplatform {
		padding: 80px 0;
	}
	.fplatform .list ul li .ico {
		height: 55px;
	}
	.fplatform .list ul li .tet h2 {
		font-size: 25px;
	}
	.fplatform .list ul li .tet p {
		margin-top: 30px;
	}
	.fthetypes {
		padding: 80px 0;
	}
	.ftitle h2 {
		font-size: 25px;
	}
	.fthetypes .fthepesw {
	    margin: 30px auto 0;
	}
	.fthepesw li {
		width: 450px;height: 290px;
	}
	.fthepesw li .txt h3 {
		font-size: 21px;line-height: 25px;
	}
	.fthepesw .swspan {
		margin: 50px 100px 0;
	}
	.flegal {
		padding: 100px 0;
	}
	.flegal .text {
		margin-top: 30px;
	}
	.ftlt .ico {
		width: 50px;
	}
	.ftlt .tet h3 {
		font-size: 25px;
		line-height: 1.3;
	}
	.ftlt .tet {
	    width: calc(100% - 64px );
	}
	.flegal .text .del {
		margin-top: 32px;
	}
	.ftiwen {
		padding: 80px 0;
	}
	.footer {
		padding: 50px 0;
	}
}
@media only screen and (max-width:1240px) {
	.wrap {
		width: 1000px;
	}
	.header {
	    padding: 30px 0 10px;
	}
	.header .logo {
	    height: 75px;
	}
	.fbanner {
	    padding: 30px 0 70px;
	}
	.fbanner .text {
	    margin-top: 50px;
	}
	.fbanner .text h2 {
		font-size: 32px;
	}
	.fbanner .text p {
		font-size: 16px;
	}
	.fplatform {
	    padding: 60px 0;
	}
	.fplatform .list ul li .ico {
	    height: 45px;
	}
	.fplatform .list ul li .tet h2 {
		font-size: 22px;
	}
	.fplatform .list ul li .tet h6 {
		font-size: 14px;
	}
	.fplatform .list ul li .tet p {
	    margin-top: 20px;
		font-size: 14px;
	}
	.fthetypes {
	    padding: 60px 0;
	}
	.ftitle h2 {
		font-size: 22px;
	}
	.ftitle h6 {
		font-size: 14px;line-height: 1.2;
	}
	.ftitle img {
		margin: 6px auto 0;
	}
	.fthepesw li {
	    width: 360px;
	    height: 235px;
	}
	.fthepesw li .txt {
		bottom: 14px;left: 15px;
		right: 15px;
	}
	.fthepesw li .txt h3 {
		font-size: 19px;
	}
	.fthepesw li .txt h6 {
		font-size: 14px;
	}
	.fthepesw .swspan {
	    margin: 40px 80px 0;
	}
	.flegal {
	    padding: 70px 0;
	}
	.flegal .text {
	    margin-top: 20px;padding-left: 20px;
	}
	.ftlt .tet h3 {
		font-size: 22px;
	}
	.ftlt .tet h6 {
		font-size: 14px;
	}
	.ftlt .ico {
	    width: 40px;
	}
	.ftlt .tet {
	    width: calc(100% - 54px );
	}
	.flegal .text .del {
	    margin-top: 20px;
		font-size: 14px;
	}
	.ftiwen .list li h2 {
		padding: 12px 50px 12px 20px;
	}
	.ftiwen .list li .fnli p {
		padding: 16px 20px;
	}
	.footer {
	    padding: 30px 0;
	}
	.footer .rt {
		margin-top: 15px;
	}
	.fvideo {
		width: 800px;
	}
	.fvideo .cha {
		height: 30px;width: 30px;
		background-size: 15px auto;
		top: -30px;right: -30px;
	}
	
}
@media only screen and (max-width:1040px) {
	.wrap {
		width: auto;
	}
	.header {
	    padding: 20px 0 10px;
	}
	.header .logo {
	    height: 60px;
	}
	.fbanner {
	    padding: 30px 15px 50px;
	}
	.fbanner .text h2 {
	    font-size: 26px;
	}
	.fbanner .text p {
		margin-top: 20px;
	}
	.fplatform {
	    padding: 50px 15px;
	}
	.fplatform .list ul li {
	    width: calc((100% - 32px)/2);
	    margin-right: 30px;
	}
	.fplatform .list ul li .ico {
	    height: 40px;
	}
	.fthetypes {
	    padding: 50px 15px;
	}
	.flegal {
		padding: 50px 15px;
	}
	.flegal .text,
	.flegal .imgs {
		float: none;width: auto;
	}
	.flegal .text .del {
		margin-top: 15px;
	}
	.flegal .imgs {
		margin-top: 20px;
	}
	.ftiwen {
	    padding: 50px 15px;
	}
	.ftiwen .list {
	    margin-top: 30px;
	}
	.footer {
	    padding: 20px 15px;
	}
	.footer .lt {
		line-height: 26px;
	}
	.footer .rt {
		height: 55px;margin-top: 12px;
	}
	.fvideo {
		width: auto;left: 50px;right: 50px;
	}
}
@media only screen and (max-width:768px) {
	.wrap {
		width: auto;
	}
	.header {
	    padding: 30px 0 10px;
	}
	.fbanner {
	    padding: 30px 15px 60px;
	}
	.fbanner .rts {
		display: none;
	}
	.fbanner .text {
	    margin-top: 0;
		float: none;width: auto;
	}
	.fbanner .text h2 {
		font-size: 24px;
	}
	.fbanner .text p {
	    margin-top: 12px;
		font-size: 14px;
	}
	.fplatform {
		padding: 45px 15px;
	}
	.fplatform .list ul {
		display: block;
	}
	.fplatform .list ul li {
		margin-right: 0;width: auto;
		margin-bottom: 35px;
	}
	.fplatform .list ul li:last-child {
		margin-bottom: 0;
	}
	.fplatform .list ul li .tet h2 {
		font-size: 19px;
	}
	.fplatform .list ul li .tet h6 {
		font-size: 12px;
	}
	.fplatform .list ul li .tet p {
		margin-top: 10px;
	}
	.ftitle h2 {
	    font-size: 19px;
	}
	.ftitle h6 {
		font-size: 12px;
	}
	.ftitle img {
	    margin: 10px auto 0;
		transform: scale(0.8);
	}
	.fthepesw li {
	    width: 280px;
	    height: 180px;
	}
	.fthepesw li .txt {
		bottom: 10px;
	}
	.fthepesw .swspan {
	    margin: 34px auto 0;
	}
	.flegal .text {
	    margin-top: 0;
	    padding-left: 0;
	}
	.ftlt .tet h3 {
	    font-size: 19px;
	}
	.ftlt .tet h6 {
	    font-size: 12px;
	}
	.ftlt .ico {
	    width: 38px;
	}
	.ftlt .tet {
	    width: calc(100% - 44px );
	}
	.ftiwen .list li {
		margin-bottom: 8px;
	}
	.ftiwen .list li h2 {
	    padding: 10px 35px 10px 15px;
		font-size: 14px;line-height: 1.8;
		background: url(../images/fico6.png) no-repeat right 12px center;
		background-size: 14px auto;
	}
	.ftiwen .list li .fnli p {
	    padding: 6px 12px;
		font-size: 14px;line-height: 1.8;
	}
	.footer .rt {
		height: 42px;margin-top: 0;
		float: none;
	}
	.footer .lt {
		float: none;margin-top: 10px;
	}
	.ftiwen .list {
	    margin-top: 20px;
	}
	.fvideo {
	    width: auto;
	    left: 15px;
	    right: 15px;
	}
	.fvideo .cha {
		right: 0;top: -35px;
	}
}