.layout {
	overflow: hidden;background: #fff;
	margin: 0 auto;position: relative;
}
.wrap {
	width: 1400px;margin: 0 auto;
}
.header {
	padding: 60px 0 15px;
}
.header .logo {
	display: block;margin-left: 15px;
	height: 95px;
}
.header .logo img {
	display: block;height: 100%;
}
.fbanner {
	padding: 32px 0 150px;
}
.fbanner img{
	width: 100%;display: block;
}
.fbanner .text {
	float: left;width: 50%;
	margin-top: 102px;
}
.fbanner .text h2 {
	line-height: 70px;font-weight: bold;
	font-size: 50px;color: #b23243;
}
.fbanner .text p {
	margin-top: 25px;font-size: 24px;
	line-height: 1.8;color: #575757;
}
.fbanner .rts {
	float: right;width: 50%;overflow: hidden;
}
.fbanner .rts .imgs {
	width: 84%;margin-right: 28px;
	float: right;
}
.fbanner .rts .imgs img {
	display: block;width: 100%;
}
.fbanner{
	position: relative;
}
.fbanner .spans{
	text-align: center;
	position: absolute;
}
.fbanner .spans span.swiper-pagination-bullet-active{
	background: #b23243;
}
.fplatform {
	background: #fdf8fb;
	padding: 120px 0 110px;
}
.fplatform 	.list ul {
	display: flex;flex-wrap:wrap;
}
.fplatform 	.list ul li {
	width: calc((100% - 72px)/2);
	margin-right: 70px;
}
.fplatform 	.list ul li:nth-child(2n) {
	margin-right: 0;
}
.fplatform 	.list ul li .ico {
	height: 70px;position: relative;
}
.fplatform 	.list ul li .ico img {
	display: block;position: absolute;z-index: 1;
	left: 5px;top: 50%;transform: translateY(-50%);
	max-height: 90%;
}
.fplatform 	.list ul li .tet {
	margin-top: 10px;
}
.fplatform 	.list ul li .tet h2 {
	font-size: 30px;line-height: 1.2;
	color: #000000;
}
.fplatform 	.list ul li .tet h6 {
	font-size: 16px;line-height: 1.5;
	color: #999999;text-transform: uppercase;
}
.fplatform 	.list ul li .tet p {
	margin-top: 40px;font-size: 16px;
	line-height: 2;color: #666666;
}
.fthetypes {
	padding: 95px 0 100px;
}
.ftitle {
	text-align: center;
}
.ftitle h2 {
	font-size: 30px;
	line-height: 1.4;
	color: #000000;
}
.ftitle h6 {
	font-size: 16px;
	line-height: 20px;
	color: #999999;
	text-transform: uppercase;
}
.ftitle img {
	display: block;
	margin: 10px auto 0;
}
.fthetypes .fthepesw {
	margin: 44px auto 0;
}
.fthepesw li {
	width: 516px;height: 330px;
	border-radius: 9px;overflow: hidden;
	position: relative;cursor: pointer;
	top: 0;transition: all 0.3s;
}
.fthepesw li:hover {
	top: -5px;
}
.fthepesw li .txt {
	position: absolute;z-index: 6;
	bottom: 24px;left: 30px;right: 30px;
}
.fthepesw li .txt h3 {
	font-size: 23px;line-height: 28px;
	color: #fff;font-weight: bold;
	text-transform: uppercase;
}
.fthepesw li .txt h6 {
	font-size: 16px;line-height: 1.5;
	color: #fff;
}
.fthepesw .swspan {
	margin: 60px 110px 0;
	height: 3px;background: #e5e5e5;
}
.fthepesw .swspan .swiper-scrollbar-drag {
	background: #d23c5e;
}
.flegal {
	background: #fdf8fb;
	padding: 140px 0;
}
.flegal .text {
	float: right;width: 50%;
	padding-left: 30px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	box-sizing: border-box;
	margin-top: 50px;
}
.flegal .imgs {
	float: left;width: 47%;
}
.flegal .imgs img {
	display: block;width: 100%;
}
.ftlt {
	overflow: hidden;
}
.ftlt .ico {
	float: left;
	width: 60px;
}
.ftlt .ico img {
	display: block;width: 100%;
}
.ftlt .tet {
	float: right;width: calc(100% - 74px );
}
.ftlt .tet h3 {
	font-size: 30px;line-height: 40px;
	color: #000;
}
.ftlt .tet h6 {
	font-size: 16px;line-height: 1.4;
	color: #999999;text-transform: uppercase;
}
.flegal .text .del {
	font-size: 16px;line-height: 2;
	color: #666666;margin-top: 46px;
}
.ftiwen {
	padding: 130px 0;
}
.ftiwen .list {
	margin-top: 40px;
}
.ftiwen .list li {
	margin-bottom: 12px;
	border: 1px solid #f3f3f3;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	box-sizing: border-box;
}
.ftiwen .list li h2 {
	transition: all 0.3s;
	padding: 16px 50px 16px 25px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	box-sizing: border-box;
	font-size: 18px;line-height: 1.5;color: #363636;
	background: url(../images/fico6.png) no-repeat right 25px center;
	cursor: pointer;
	box-shadow: 0 0 6px 1px rgba(80,80,80,0.1);
	
}
.ftiwen .list li .fnli {
	overflow: hidden;display: none;
}
.ftiwen .list li .fnli p{
	border: 1px solid #eeeeee;
	border-top: none;
	padding: 22px 25px;
	font-size: 16px;
	line-height: 1.5;
	color: #666666;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	box-sizing: border-box;
	transition: all 0.3s;
}

.footer {
	background-image: linear-gradient(to right, #991e52, #d33c5e);
	padding: 70px 0 75px;
}
.footer .lt {
	float: left;
	font-size: 14px;
	line-height: 30px;
	color: #fff;
}
.footer .rt {
	float: right;margin-top: 20px;
	height: 61px;
}
.footer .rt a {
	display: block;height: 100%;
}
.footer .rt img {
	display: block;height: 100%;
}
.fvideobj {
	position: fixed;
	z-index: 111;top: 0;
	bottom: 0;left: 0;right: 0;
	background: rgba(0,0,0,0.5);
	display: none;
}
.fvideo {
	width: 1000px;position: fixed;
	z-index: 122;top: 50%;transform: translateY(-50%);
	left: 0;right: 0;margin: 0 auto;
	display: none;
}
.fvideo .video1 {
	display: block;width: 100%;
}
.fvideo .cha {
	position: absolute;z-index: 11;
	height: 40px;width: 40px;
	background: url(../images/fico7.png) no-repeat center #fff;
	font-size: 16px;top: -40px;right: -40px;
	border-radius: 50%;overflow: hidden;
	cursor: pointer;transition: all 0.3s;
}
.fvideo .cha:hover {
	transform: rotate(270deg);
}