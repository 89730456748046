.legal{
    padding:0;margin:0;box-sizing: border-box;font-family: 'Poppins';
    width:100vw;
    min-height: 100vh;
    display: flex;
    justify-content: center;
}

/* .legal  header{
    width:100vw;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
} */
.legal li{
    line-height: 150%;
}
.legal h4{
    font-weight: 500;
}
.legal .container{
    max-width: 1064px;
    margin:10px 0;
    border: 1px solid #b2bec3;
    border-radius:5px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding:1rem 2rem ;
}
.legal .span{
    color: #5f6368;
    font-size: 14px;
    line-height: 1.4286;
}
.legal .left{
    flex-basis: 70%;
}
.legal .right{
    flex-basis: 30%;
}
.legal .right img{
    width:100%;
}
.legal .right p{
    text-align: center;
    margin:auto;
}
.legal h3{
    color:#2d3436;
    margin:1rem 0;
    font-size: 1.75rem;
    font-weight: 600;
    
}
.legal .sp{
    justify-content: space-around;
}
.legal .input{
    width:49%;
    padding:0.5rem 0.7rem;
    outline:none;
    border:1px solid #b2bec3;
    font-size:1rem;
    border-radius: 5px;
    margin-bottom:1.5rem;
}
.legal form input[type="text"]{
    width:49%;
    padding:0.5rem 0.7rem;
    outline:none;
    border:1px solid #b2bec3;
    border-radius: 5px;
    margin-bottom:1.5rem;
}

.legal form input[type="email"]{
    width:100%;
    padding:0.5rem 0.7rem;
    outline:none;
    border-radius: 5px;
    border:1px solid #b2bec3;
    margin-bottom:1.5rem;
    border-radius:5px;
}
.legal form span{
    color:grey;
    width:100%;
    font-size:0.8rem;
}
.legal form a{
    text-decoration:none;
    font-weight:600;
    font-size:0.8rem;
    color:#3498db
}

.legal .b-links{
    width:100%;
    display: flex;
    justify-content:space-between;
    align-items: center;
    margin-top:2.5rem
}

.legal form input[type="submit"]{
    background: #3498db;
    color: white;
    padding:0.5rem 1.3rem;
    border-radius: 5px;
    border:none;
    outline:none;
    cursor:pointer;
}
.legal img{
    width:100%;
    max-width: 282px;
    margin-bottom: 5px;
    margin-left:-10px;
}
.legal form input{
    transition: 0.4s;
}
.legal form input:focus{
    border:2px solid #3498db;
}
.legal .submitDiv{
    padding-top: 1rem;
    justify-content: flex-end;
    display: flex;
}
.legal .rdtDays tr .dow{
    border:0px !important;
    border-bottom: 1px solid #e3e3e3 !important; 
}
.legal .rdtPicker .rdtTimeToggle{
    border: 1px solid #e3e3e3 !important; 
}
.legal .rdtPicker td {
    text-align: center;
    padding: 1px;
    border: 0 !important;
}

.legal .rdtPicker th.rdtNext, .rdtPicker th.rdtPrev{
    border: 0 !important;
}
.legal .rdtPicker th.rdtSwitch{
    border: 0 !important;
}
@media only screen and (max-width:770px) {
    .legal body{
        overflow-x:hidden;
    }
    .legal header{
        align-items: flex-start;
        padding:0.2rem
    }
    .legal .right{
        display: none;
    }
    .legal .container{
        flex-direction:column;
        border:none;
    }
    .legal form input [type="text"]{
        width:100%;
    }
    .legal .b-links{
        margin-top:1rem
    }
    .legal .left{
        flex-basis: 100%;
    }
    .legal img{
        max-width: 182px;
    }
    .legal h3{
        color:#2d3436;
        margin:1rem 0;
        font-size: 1.5rem;
        font-weight: 600;
        
    }
    
}